import React from "react";
import NavHeader from "./NavHeader";
// import { WindowPosition } from "../../utils/HelperMethod";

const MainNavigation = (props) => {
  return (
    <>
      <NavHeader />
    </>
  );
};

export default MainNavigation;
